import { useState, useEffect } from "react";
import "./StickyFooter.css"; 

export default function StickyFooter() {

  return (
    <footer className="sticky-footer">
        <a className='purple' onClick={()=> window.open('https://www.spoteet.com/cgu', '_self')}>
        Conditions générales d’utilisation
        </a>
        <p className='pink'>
        Spoteet Inc, tous droits réservés - 2025
        </p>
    </footer>
  );
}

import { useState, useEffect } from "react";
import "./StickyHeader.css"; 

export default function StickyHeader() {
  const [activeTab, setActiveTab] = useState("Concept");
  const sections = ["Concept", "Comment ça marche ?", "La communauté"];

  useEffect(() => {
    const sections = [
      { id: "first-page", name: "Concept" },
      { id: "scd-page", name: "Comment ça marche ?" },
      { id: "third-page", name: "La communauté" },
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveTab(sections.find((s) => s.id === entry.target.id)?.name || "Concept");
          }
        });
      },
      {
        root: null, // Observe la fenêtre entière
        rootMargin: "-72px 0px 0px 0px", // Décalage pour compenser le header
        threshold: 0.1, // Déclenche l'événement quand 10% de la section est visible
      }
    );

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);
  
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const yOffset = -72; // Décalage pour compenser le header fixe
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
  
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <header className="sticky-header">
      {/* Logo */}
      <div className="logo">
        <div className="logo-icon"/>
        <span className="logo-text">Spoteet</span>
      </div>

      {/* Navigation Pills */}
      <div className="nav-pills nav-pills-header">
        <button
            key={'Concept'}
            className={`pill ${activeTab === 'Concept' ? "active" : ""}`}
            onClick={() => scrollToSection('first-page')}
          >
            Concept
        </button>
        <button
            key={'Comment ça marche ?'}
            className={`pill ${activeTab === 'Comment ça marche ?' ? "active" : ""}`}
            onClick={() => scrollToSection('scd-page')}
          >
            Comment ça marche ?
        </button>
        <button
            key={'La communauté'}
            className={`pill ${activeTab === 'La communauté' ? "active" : ""}`}
            onClick={() => scrollToSection('third-page')}
          >
            La communauté
        </button>
      </div>

      {/* App Store & Play Store Icons */}
      <div className="store-icons">
        <div className='icon-container bg-black-purple' onClick={()=> window.open("https://testflight.apple.com/join/eKfvddFJ", "_blank")}>
          <div className='icon appstore'></div>
        </div>
        <div className='icon-container bg-black-purple' onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.spoteet", "_blank")}>
          <div className='icon playstore'></div>
        </div>
      </div>
    </header>
  );
}

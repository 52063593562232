import React, { useState } from "react";
import "./Slider.css";
import StepComponent from "../Step/StepComponent";

function SliderComponent() {
  const [activeTab, setActiveTab] = useState('Je suis artiste');
  const sections = ['Je suis artiste', 'Je suis spectateur'];
  const [showFirstSet, setShowFirstSet] = useState(true);

  const toggleSlides = () => {
    setShowFirstSet(!showFirstSet);
    if (activeTab === 'Je suis artiste') {
      setActiveTab('Je suis spectateur')
    } else {
      setActiveTab('Je suis artiste')
    }
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
    console.log('User agent:', userAgent);

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod|Mac/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
}

  const openStore = () => {
    var os = getMobileOperatingSystem();
    console.log('OS = ', os);
    if (os == 'iOS') {
      var newWindow = window.open('https://testflight.apple.com/join/eKfvddFJ', '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      var newWindow = window.open('https://play.google.com/store/apps/details?id=com.spoteet', '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <div className="mt-40 w-100">
      <div className="nav-pills">
      {sections.map((section) => (
        <button
          key={section}
          className={`pill ${activeTab === section ? "active" : ""}`}
          onClick={toggleSlides}
        >
          {section}
        </button>
      ))}
      </div>
      <div className="slider-container">
        <div className="slides-wrapper">
          <div className={`slides ${showFirstSet ? "show-first" : "show-second"}`}>
            <div className="slide-set first-set">
              <StepComponent stepClassName='step_1_spoter' subtitle='Créez un évènement'/>
              <StepComponent stepClassName='step_2_spoter' subtitle='Rendez-vous sur place pour jouer'/>
              <StepComponent stepClassName='step_3_spoter' subtitle='Laissez les autres vous trouver !'/>
            </div>
            <div className="slide-set second-set">
              <StepComponent stepClassName='step_1_spectator' subtitle='Cherchez un évènement'/>
              <StepComponent stepClassName='step_2_spectator' subtitle='Rendez-vous sur place'/>
              <StepComponent stepClassName='step_3_spectator' subtitle='Découvrez des artistes'/>
            </div>
          </div>
        </div>
        <button onClick={openStore} className='mt-40'>Démarrer</button>
      </div>
    </div>
  );
};

export default SliderComponent;

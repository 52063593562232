import "./Step.css";

function StepComponent({stepClassName, subtitle}: {stepClassName: string, subtitle: string}) {

  return (
    <div className='slide'>
      <div className={`step ${stepClassName}`} />
      <p>{subtitle}</p>
    </div>
  );
}

export default StepComponent;
import { useState } from 'react';
import StickyHeader from '../../component/StickyHeader/StickyHeader';
import "./LandingPage.css"; 
import SliderComponent from '../../component/Slider/SliderComponent';
import StickyFooter from '../../component/StickyFooter/StickyFooter';

function LandingPage() {

  return (
    <div className='content'>
        <StickyHeader/>
        <div className='first-page' id='first-page'>
          <div className='description'>
            <p className='subtitle'>Partout, tout le temps, avec tout le monde</p>
            <p>L’app qui permet aux artistes de <b>se produire partout</b> et d’être <b>facilement découverts</b>.</p>
            <p className='text-center-mobile'>Spoteet, c’est <b>le premier réseau social de la musique live</b>, dans le monde réel qui vous permet de <b>suivre vos artistes</b> préférés, d’<b>interagir avec eux</b> et d’être <b>informé en temps réel</b> de leurs performances, afin de ne jamais rater un live.</p>
            <div className='circle-button'></div>
          </div>
          </div>
        <div className='other-page'>
        <div className='page' id='scd-page'>
            <p className='subtitle text-center'>Comment ça marche ?</p>
            <SliderComponent/>
          </div>
          <div className='page' id='third-page'>
            <p className='text-medium text-center'>Un réseau d’artistes engagé,</p>
            <p className='text-medium text-center purple mt-10'>pour rencontrer son public</p>
            <div className='app-image' />
            <button className='mt-40 bg-purple black-purple' onClick={()=> window.open('https://www.instagram.com/spoteet_fr/', '_blank')}>Rejoindre la communauté</button>
          </div>
        </div>
        <StickyFooter/>
    </div>
  );
}

export default LandingPage;
